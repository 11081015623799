/* Navigation Styles */
.ui.inverted.top.fixed.menu {
    padding: 0px 355px 0px 305px;
    background-color: #1b1c1d !important;
}
.ui.selection.dropdown{
    min-width: 5em !important;
}
.ui.menu{
    background-color: #1b1c1d !important;
}
.ui.selection.dropdown[name="versionSwitcher"]{
    margin-right: 1em !important;
}

/* Footer Styles */
.ui.vertical.segment{
    border-bottom: none;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.ui.inverted.vertical.segment {
    margin: 2em 0em 0em; 
    padding: 2em 0em;     
}


